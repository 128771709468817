import React, { Component } from "react"
// import { Link } from "gatsby"
import { navigate } from "@reach/router" 
import PropTypes from "prop-types"
import { inject, observer } from "mobx-react"
import { Grid } from "@material-ui/core"
import Layout from "../../components/Layout"
import SEO from "../../components/seo"
import Section from "../../components/Section"
import { withStyles } from "@material-ui/core/styles"
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Badge from "../../components/Badge";
import Paper from '@material-ui/core/Paper';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMapMarkerAlt, faUserFriends, faClock, faAddressCard } from "@fortawesome/free-solid-svg-icons"
import TextField from '@material-ui/core/TextField';
import { halfDay, checkBetween, numberWithCommas } from '../../utils/common'
import auth from "../../utils/auth";
import moment from "moment"
import "moment/locale/th"
moment.locale("th")

class Booking extends Component {
  constructor(props) {
    super(props)
    this.state = {
      firstname: '',
      lastname: '',
      email: '',
      confirmEmail: '',
      mobile: '',
      checkIn: '',
      checkOut: '',
      adults: 2,
      adultsKid: 0,
      adultsPlus: 0,
      id: '',
      specialPrice: 0,
      price: 0,
      adultsPrice: 0,
      vatRate: 0,
      vatPrice: 0,
      totalPrice: 0,
    }
  }
  componentDidMount = async () => {

    const user = auth.getUserInfo();
    if(user){
      this.setState((prevState) => {
        prevState.firstname = user.first_name
        prevState.lastname = user.last_name
        prevState.email = user.email
        prevState.confirmEmail = user.email
        prevState.mobile = user.phone
        return prevState
      })
    }

    let params = new URLSearchParams(this.props.location.search);
    const id = params.get("id");
    if (id) {
      await this.props.house.fetchHouse(id)
      const search = JSON.parse(localStorage.getItem("search"))
      const house = this.props.house.getHouseJS()
      let sumPrice = { special_price: 0, price: 0 };
      if (house.prices.length) {
        const filterPrice = house.prices.filter(val => {
          return checkBetween(val.calendar_date, search.checkIn, search.checkOut)
        })
        sumPrice = (filterPrice || []).reduce(function (previousValue, currentValue) {
          return {
            special_price: previousValue.special_price + currentValue.special_price,
            price: previousValue.price + currentValue.price,
            price_per_person: previousValue.price_per_person + currentValue.price_per_person
          }
        });
      }
      let adultsPrice = 0;
      let priceKid = 0;
      let priceOld = 0;

      let old = 0;
      let kid = 0;

      const adultsPerHouse = (+search.adults + +search.adultsKid) - +house.normal_person;
      const checkIn = moment(search.checkIn, "YYYY-MM-DD");
      const checkOut = moment(search.checkOut, "YYYY-MM-DD");
      const sumDay = checkOut.diff(checkIn, 'days')
      if (adultsPerHouse > 0) {
        const checkOldAdults = +adultsPerHouse - +search.adultsKid;
        if (checkOldAdults <= 0) {
          // คนที่เกินราคาเด็กทั้งหมด
          kid = adultsPerHouse;
        } else {
          // ราคาผู้ใหญ่
          old = adultsPerHouse - +search.adultsKid
          if (old > 0) {
            kid = +search.adultsKid;
          }
        }
        priceOld = sumPrice.price_per_person * old
        priceKid = kid * +house.kid_price * sumDay
        adultsPrice = priceOld + priceKid;

        // adultsPlus = (+search.adults + +search.adultsKid) - +house.normal_person;
        // adultsPrice = sumPrice.price_per_person * adultsPlus
      }
      const config = await this.props.config.getConfig(1);
      this.setState((prevState) => {
        prevState.checkIn = search.checkIn
        prevState.checkOut = search.checkOut
        prevState.adults = search.adults
        prevState.adultsKid = search.adultsKid
        prevState.id = id
        prevState.adultsPlus = kid+old
        prevState.specialPrice = sumPrice.special_price
        prevState.adultsPrice = adultsPrice
        prevState.vatRate = (+config.value_a)
        prevState.vatPrice = ((sumPrice.special_price + adultsPrice) * (+config.value_a/100)).toFixed(2)
        prevState.totalPrice = ((sumPrice.special_price + adultsPrice) + ((sumPrice.special_price + adultsPrice) * (+config.value_a/100))).toFixed(2)
        prevState.price = sumPrice.price
        return prevState
      })
    } else {
      navigate(`/404`)
    }
  }

  onTextFieldChange = (value, key) => {
    this.setState((prevState) => {
      prevState[key] = value
      return prevState
    })
  };

  popupStatus = (status, title, text) => {
    Swal.fire({
      icon: status,
      title: title,
      text: text,
    })
  }


  validate() {
    if (!this.state.firstname || this.state.firstname === "" || this.state.firstname === null || this.state.firstname === undefined) {
      this.popupStatus('warning', '', 'กรุณากรอกชื่อ')
      return false
    }
    if (!this.state.lastname || this.state.lastname === "" || this.state.lastname === null || this.state.lastname === undefined) {
      this.popupStatus('warning', '', 'กรุณากรอกนามสกุล')
      return false
    }
    if (!this.state.email || this.state.email === "" || this.state.email === null || this.state.email === undefined) {
      this.popupStatus('warning', '', 'กรุณากรอกอีเมล')
      return false
    }
    if (!this.state.confirmEmail || this.state.confirmEmail === "" || this.state.confirmEmail === null || this.state.confirmEmail === undefined) {
      this.popupStatus('warning', '', 'กรุณากรอกยืนยันอีเมล')
      return false
    }
    if (this.state.email !== this.state.confirmEmail) {
      this.popupStatus('warning', '', 'ยืนยันอีเมลไม่ถูกต้อง')
      return false
    }
    if (!this.state.mobile || this.state.mobile === "" || this.state.mobile === null || this.state.mobile === undefined) {
      this.popupStatus('warning', '', 'กรุณากรอกเบอร์โทรศัพท์')
      return false
    }
    if (+this.state.adults === 0 && +this.state.adultsChild === 0) {
      this.popupStatus('warning', '', 'จำนวนระบุจำนวนผู้เข้าพัก')
      return false
    } else {
      const house = this.props.house.getHouseJS()
      console.log('this.state.adults', this.state.adults);
      console.log('this.state.adultsChild', this.state.adultsChild);
      const sumAdults = (+this.state.adults) + (+this.state.adultsChild);
      console.log('sumAdults', sumAdults);
      console.log('house.max_person', house.max_person);
      if (sumAdults > +house.max_person) {
        this.popupStatus('warning', '', 'จำนวนผู้เข้าพักเกินจำนวนสูงสุด')
        return false
      }
    }
    if (this.validateEmail()) {
      return true
    } else {
      this.popupStatus('warning', '', 'กรุณากรอกอีเมลให้ถูกต้อง')
      return false
    }

  }

  validateEmail() {
    // eslint-disable-next-line
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(this.state.email);
  }

  handleSubmit = async () => {
    if (this.validate()) {
      if (+this.state.specialPrice > 0) {
        Swal.fire({
          icon: 'info',
          title: 'กรุณายืนยันการจอง',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'ยืนยัน',
          cancelButtonText: 'ยกเลิก',
          reverseButtons: true,
          showLoaderOnConfirm: true,
          preConfirm: () => {
            let params = new URLSearchParams(this.props.location.search);
            const id = params.get("id");
            const checkIn = halfDay(this.state.checkIn, 'start');
            const checkOut = halfDay(this.state.checkOut, 'end');
            let data = {
              start_date: checkIn,
              end_date: checkOut,
              adults: +this.state.adults,
              adults_kid: +this.state.adultsKid,
              house_id: +id,
              firstname: this.state.firstname,
              lastname: this.state.lastname,
              mobile: this.state.mobile,
              email: this.state.email,
              price: this.state.specialPrice + this.state.adultsPrice,
              vat_price: (+this.state.vatPrice).toFixed(2),
              vat_rate: (+this.state.vatRate).toFixed(2),
              total_price: (+this.state.totalPrice).toFixed(2)
            }
            return this.props.booking.submitBooking(data)
              .then(result => {
                return result;
              })
              .catch(err => {
                Swal.fire({
                  icon: 'error',
                  title: err.message,
                  confirmButtonText: 'ตกลง',
                });
                return false;
              });
          },
          allowOutsideClick: () => !Swal.isLoading(),
        }).then(async result => {
          if (result.value) {
            const bid = result.value.bid;
            Swal.fire({
              icon: 'success',
              title: 'บันทึกข้อมูลเรียบร้อย',
              showConfirmButton: true,
              confirmButtonText: 'ตกลง',
            }).then((result) => {
              if (result.value) {
                navigate(`/Booking/detail?bid=${bid}`)
              }
            })
          }
        });
      } else {
        this.popupStatus('error', 'ขออภัย', 'ข้อมูลผิดพลาด')
      }
    }
  }


  render() {
    const { classes } = this.props
    const imgShare = `${process.env.AWS_S3_URL}/assets/share-social-new.jpg`
    const house = this.props.house.getHouseJS()
    const discount = (((this.state.price - this.state.specialPrice) / this.state.price) * 100).toFixed(0);
    const checkIn = moment(this.state.checkIn, "YYYY-MM-DD");
    const checkOut = moment(this.state.checkOut, "YYYY-MM-DD");
    let houseImageHighLight = house.house_images ? house.house_images.find(f => f.is_highlight) : null
    if (!houseImageHighLight) {
      houseImageHighLight = house.house_images.find(e => !!e) || { url: `${process.env.AWS_S3_URL}/assets/img-null.png` };
    }
    return (
      <Layout page="booking">
        <div className={classes.bookingContainer}>
        <SEO
          title="BEST HAVEN POOLVILLA"
          description="แหล่งรวมบ้านพักพูลวิลล่าพร้อมสระว่ายน้ำส่วนตัว
    และบ้านพักตากอากาศที่มากที่สุดและราคาดีที่สุดในโซนเขาใหญ่
    และบริเวณใกล้เคียงโดยสามารถจองผ่านทางเราโดยตรงได้ทันที"
          image={imgShare}
        />
          <Section>{"รายละเอียดการจอง"}</Section>
          <div className={classes.spaceBox}></div>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={8}>
              <Paper >
                <div className={classes.bookingForm}>
                  <h3><FontAwesomeIcon icon={faAddressCard} /> กรุณากรอกข้อมูลของท่าน</h3>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6}>
                      <div className={classes.boxForm}>
                        <h4>ชื่อ</h4>
                        <TextField
                          variant="outlined"
                          fullWidth
                          value={this.state.firstname}
                          onChange={event => this.onTextFieldChange(event.target.value, 'firstname')}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <div className={classes.boxForm}>
                        <h4>สกุล</h4>
                        <TextField
                          variant="outlined"
                          fullWidth
                          value={this.state.lastname}
                          onChange={event => this.onTextFieldChange(event.target.value, 'lastname')}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6}>
                      <div className={classes.boxForm}>
                        <h4>อีเมล</h4>
                        <TextField
                          variant="outlined"
                          fullWidth
                          value={this.state.email}
                          onChange={event => this.onTextFieldChange(event.target.value, 'email')}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <div className={classes.boxForm}>
                        <h4>พิมพ์ยืนยันอีเมลอีกครั้ง</h4>
                        <TextField
                          variant="outlined"
                          fullWidth
                          value={this.state.confirmEmail}
                          onChange={event => this.onTextFieldChange(event.target.value, 'confirmEmail')}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6}>
                      <div className={classes.boxForm}>
                        <h4>หมายเลขโทรศัพท์</h4>
                        <TextField
                          type="number"
                          variant="outlined"
                          fullWidth
                          value={this.state.mobile}
                          onChange={event => this.onTextFieldChange(event.target.value, 'mobile')}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Button size='large' style={{ marginTop: '30px' }} variant="contained" color="primary" fullWidth onClick={() => this.handleSubmit()}>
                    ยืนยันการจอง
                  </Button>
                </div>

              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Card className={classes.root}>
                <CardActionArea>
                  {
                    houseImageHighLight &&
                    <CardMedia
                      className={classes.media}
                      image={houseImageHighLight.url}
                      title={house.name}
                    />
                  }
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="h6">
                      {house.name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      {(house.locations || []).map(element => {
                        return <Badge key={element.id} color="yellow"><FontAwesomeIcon icon={faMapMarkerAlt} /> {element.name}</Badge>
                      })}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
              <Card className={classes.root} style={{ marginTop: '20px' }}>
                <CardActionArea>
                  <CardContent>
                    <div className={classes.titleText}>
                      <div>{moment(this.state.checkIn).format("DD MMM YYYY")} - {moment(this.state.checkOut).format("DD MMM YYYY")}</div>
                      <div>{checkOut.diff(checkIn, 'days')} คืน</div>
                    </div>
                    {(+this.state.adults > 0) && <div className={classes.subTitleText}>
                      <div className={classes.lightText}>- ผู้ใหญ่</div>
                      <div className={classes.lightText}>{this.state.adults} คน</div>
                    </div>}
                    {(+this.state.adultsKid > 0) && <div className={classes.subTitleText}>
                      <div className={classes.lightText}>- เด็ก</div>
                      <div className={classes.lightText}>{this.state.adultsKid} คน</div>
                    </div>}
                    <div className={classes.subTitleText}>
                      <div>ผู้เข้าพักทั้งหมด</div>
                      <div>{+this.state.adults + +this.state.adultsKid} คน</div>
                    </div>
                    <br />
                    <hr />
                    <div className={classes.lightText}><FontAwesomeIcon icon={faUserFriends} /> ห้องพักสำหรับ {house.max_person} คน  </div>
                    <div className={classes.lightText}><FontAwesomeIcon icon={faClock} /> เช็คอินตั่งแต่ {house.check_in ? house.check_in.substring(0, house.check_in.length - 3) : '14:00'} น  </div>
                    <div className={classes.lightText}><FontAwesomeIcon icon={faClock} /> เช็คเอาท์ก่อน {house.check_out ? house.check_out.substring(0, house.check_out.length - 3) : '12:00'} น  </div>
                  </CardContent>
                </CardActionArea>
              </Card>
              <Card className={classes.root} style={{ marginTop: '20px' }}>
                <CardActionArea>
                  <CardContent>
                    {discount > 0 &&
                      <div>
                        <div className={classes.subTitleText}>
                          <div>ราคาที่พัก (x {checkOut.diff(checkIn, 'days')} คืน)</div>
                          <div style={{ textDecoration: 'line-through' }}>฿ {numberWithCommas(this.state.price)}</div>
                        </div>
                        <div className={classes.titleText} style={{ color: 'red', fontSize: '14px' }}>
                          <div>ส่วนลด</div>
                          <div>{discount}%</div>
                        </div>
                      </div>
                    }
                    <div className={classes.subTitleText}>
                      <div>ราคาที่พัก (x {checkOut.diff(checkIn, 'days')} คืน)</div>
                      <div>฿ {numberWithCommas(this.state.specialPrice)}</div>
                    </div>
                    <div className={classes.subTitleText}>
                      <div>ผู้เข้าพักเกิน (x {this.state.adultsPlus} คน)</div>
                      <div>฿ {numberWithCommas(this.state.adultsPrice)}</div>
                    </div>
                    <div className={classes.subTitleText}>
                      <div>ราคาที่จ่าย (x {checkOut.diff(checkIn, 'days')} คืน)</div>
                      <div>฿ {numberWithCommas(this.state.specialPrice + this.state.adultsPrice)}</div>
                    </div>
                    <div className={classes.titleText} style={{ color: 'gray', fontSize: '14px' }}>
                      <div>ภาษี ({this.state.vatRate}%)</div>
                      <div>฿ {numberWithCommas(this.state.vatPrice)}</div>
                    </div>
                    {/* <div className={classes.titleText} style={{ color: 'gray', fontSize: '14px' }}>
                      <div>ค่าประกัน </div>
                      <div>฿ {numberWithCommas(house.insurance_price || 0)}</div>
                    </div> */}
                    <div className={classes.titleText} style={{ color: 'green', fontSize: '14px' }}>
                      <div>ค่าธรรมเนียมการจอง</div>
                      <div>ฟรี</div>
                    </div>
                    <br />
                    <hr />
                    <div className={classes.titleText}>
                      <div><b>ทั้งหมด</b></div>
                      <div><b>฿ {numberWithCommas((+this.state.totalPrice).toFixed(2))}</b></div>
                      {/* <div><b>฿ {numberWithCommas(((+this.state.totalPrice) + (+house.insurance_price)).toFixed(2))}</b></div> */}
                    </div>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>
        </div>
      </Layout>)
  }
}
const styles = () => ({
  boxForm: {
    marginTop: '24px',
    fontFamily: `Kanit`,
    '& h4': {
      marginBottom: '10px',
      fontFamily: `Kanit`,
    },
    '& input': {
      fontFamily: `Kanit`
    }
  },
  bookingContainer: {
    '& span': {
      fontFamily: `Kanit`,
    },
  },
  bookingForm: {
    padding: '16px',
    '& h3': {
      marginTop: '30px',
      fontFamily: `Kanit`,
      color: '#444444'
    },
    '& h6': {
      marginLeft: '10px',
      fontFamily: `Kanit`,
      color: '#3f51b5'
    }
  },
  spaceBox: {
    height: "30px",
  },
  root: {
    // maxWidth: 345,
  },
  media: {
    fontFamily: `Kanit`,
    width: '100%',
    height: '200px'
  },
  titleText: {
    fontFamily: `Kanit`,
    display: 'flex',
    justifyContent: 'space-between',
    color: '#444444'
  },
  subTitleText: {
    fontFamily: `Kanit`,
    display: 'flex',
    justifyContent: 'space-between',
  },
  lightText: {
    fontFamily: `Kanit`,
    fontSize: '13px'
  }
})

Booking.propTypes = {
  classes: PropTypes.object.isRequired,
}
export const page = inject("house", "search", "booking", "config")(observer(Booking))
export default withStyles(styles, { withTheme: true })(page)